#bulk-edit-modal-header {
  margin-bottom: 1rem;

  font-size: 16pt;
}

#bulk-edit-modal-body {
  height: 400px;
  
  display: flex;
  flex-direction: column;
  
  overflow-y: scroll;

  .bulk-edit-modal-row {
    margin: 0.3rem;
  }
}

#bulk-edit-modal-footer {
  display: flex;

  margin-top: 1rem;
}

.bulk-edit-input {
  width: 20rem;
  
  padding: 0.3rem;
}

.bulk-edit-select {
  width: 20rem;

  padding: 0.3rem;
}
