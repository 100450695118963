#reviewer-container {
  height: 100%;

  display: flex;
  flex-direction: row;
}

#left-nav-reviewer {
  height: 100%;
  width: 300px;

  display: flex;
  flex-direction: column;

  color: #4b4f54;
  background-color: #d8d4ce;
}

#reviewer-logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 1rem;

  border-bottom: 1px solid #ddd;
}

#reviewer-heading {

}

.reviewer-action {
  width: 300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  margin: 0.3rem;
  border: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }

  span {
    margin-right: 1rem;
  }
}

#report-table-container {
  margin: 0.3rem;
  margin-top: 1rem;

  font-size: 12pt;
}

.clickable {
  cursor: pointer;
}

#reviewer-content-body {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: scroll;
}

#sending-to-cas-load {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#report-tables-container {
  height: 100%;
  width: 100%;
}

#metrics-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

#histogram {
  width: 100%;
  display: flex;
  justify-content: center;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}
