.view-notes-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 4rem;

  background-color: whitesmoke;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11pt;

  user-select: none;

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }

  i {
    margin-right: 0.3rem;
  }
}

.view-notes-button.disabled {
  color: #bbb;
  background-color: #eee;
}
