.import-options-labels {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #ddd;
}

.import-options-group {
  margin: 0rem 1.5rem;
  font-size: 14pt;

  text-align: center;
}
