#upload-history-table {
  width: 100%;
  margin: 1rem;
  
  font-size: 10pt;
}

.upload-history-table-header {
  max-width: 200px;

  padding: 0.7rem;

  background-color: #f0f0f0;
}

.upload-history-table-controls {
  margin: 0 1rem;
  margin-top: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-history-table-label {
  font-weight: bold;
  font-size: 18pt;
}

.upload-history-table-row {
  border: 1px solid #ddd;
}

.upload-history-table-row:hover {
  border: 2px solid rgb(175, 238, 238);
}

.upload-history-table-cell {
  max-width: 200px;
  padding: 0.5rem;

  background-color: white;
  color: #3f3f3f;
}

.upload-history-table-cell a {
  text-decoration: none;
  color: #3f3f3f;
}

.sort-controls {
  margin-left: 1rem;
}

.pagination {
  display: flex;
  align-items: center;

  .page-control {
    width: 2rem;
    height: 2rem;

    margin: 0.2rem;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #ddd;
    border-radius: 0.3rem;

    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}
