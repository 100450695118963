#fields-control {
  padding: 1rem;
  
  background: white;
  font-size: 13px;
  border: 2px solid #ddd;
  border-radius: 0.3rem;
  
  #fields-control-select-container {
    height: 200px;
    overflow-y: scroll;
  }

  .field-control-select {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.3rem;
    }
  }
}

#fields-control-input {
  margin-bottom: 1rem;
  padding: 0.3rem;
}

.invisible {
  display: none;
}

#hidden-column-clear {
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  font-weight: bold;
  color: #555;

  &:hover {
    background-color: rgb(0, 132, 255);
    color: white;
  }
}
