#demote-modal-header {
  margin-bottom: 1rem;

  font-size: 16pt;
}

#demote-modal-body {
  margin-left: 1rem;
  margin-right: 1rem;

  > textarea {
    margin-bottom: 0.8rem;
  }
}

#demote-modal-footer {
  display: flex;

  margin-top: 1rem;
}

#demote-modal-textarea {
  width: 100%;
  min-height: 8rem;
}
