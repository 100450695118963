.aim-nav-link {
  width: 100%;

  display: flex;
  align-items: center;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.5em;

  color: #4b4f54;

  transition: background-color 0.5s;

  .aim-nav-link-label {
    margin-left: 1rem;

    color: #4b4f54;
  }

  &:hover {
    background-color: #bfb8af;
    font-weight: bold;
  }
}

.active-aim-nav-link {
  font-weight: bold;
}

.nav-item-link-tag:hover {
  text-decoration: none;
}
