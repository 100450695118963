h2, h6 {
  margin: 0;
  padding: 0;
}

#editor-container {
  width: 100%;
  height: 100%;

  display: flex;

  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#left-nav {
  width: 13%;
  min-width: 200px;

  color: #4b4f54;
  background-color: #d8d4ce;
}

#nav-search-space {
  height: 50px;

  display: flex;

  background-color: #d88c29;
}

#nav-search-icon {
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

#nav-search-box {
  width: 180px;

  padding-left: 0.5rem;

  border: none;
  background-color: white;
}

#nav-link-container {
  margin-top: 2em;
}

.aim-nav-group {
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;

  text-decoration: none;
}

.aim-nav-group .aim-nav-link {
  padding-left: 1rem;
}

.aim-active-nav-link {
  font-weight: bold;
}

/*
  Everything right of the nav bar
*/
#right-content {
  width: 87%;

  display: flex;
  flex-direction: column;

  background-color: #f9f8f7;
}

#user-button {
  margin: 1rem;
}

#tab-space {
  height: 50px;

  border-bottom: 1px solid #e1e2e2;
}

#editor-space {
  width: 100%;
  height: 85%;

  flex-grow: 1;

  background-color: #faf7f7;
}

/*

  Projects

*/
#projects-container {
  height: 100%;

  display: flex;
  flex-direction: column;

  color: #3f3f3f;
}

#projects-container-header {
  padding: 2rem;
}

#projects-container-body {
  flex-grow: 1;

  margin-top: 2rem;

  border: 2px solid #ddd;
  /* background-color: #faf7f7; */

  overflow-x: scroll;
  overflow-y: scroll;
}

#project-table-controls {
  height: 50px;

  display: flex;
  align-items: center;

  background-color: white;
}

#projects-container-footer {
  padding: 1rem;
}

#project-table {
  margin: 0.5rem;

  font-size: 14pt;
  background-color: white;
  box-shadow: 5px 5px 10px #ddd;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.project-table-row {
  border: 0px solid #aaddff00;
  border-color: #aaddff00;
  transition-property: border-color;
  transition-duration: 0.2s;
}

.project-table-row:hover {
  border: 2px solid #aaddffff;
}

.project-table-row td {
  min-width: 200px;

  padding: 0.5rem;

  transition-property: padding-top;
  transition-duration: 0.4s;
  transition-property: padding-bottom;
  transition-duration: 0.4s;
}

.active-project-table-row {
  border: 2px solid #ddd;
}

.active-project-table-row td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table-status-column {
  color: white
}

#admin-project-nav {
  background-color: #e7e4df;
}

#admin-project-nav .aim-nav-link {
  padding-left: 3rem;
}

.project-nav-header {
  width: 100%;

  display: flex;
  align-items: center;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 2em;

  text-decoration: none;
}

.project-nav-header p {
  margin: 0rem;
  margin-left: 1rem;

  font-weight: bold;
  font-size: 14pt;
}

#confirm-message-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#deduplicator-container {
  height: 100%;

  margin: 1rem;
}

#merge-grid-instructions {
  padding: 1rem;

  background-color: white;
}

#merge-grid-instructions p {
  margin: 0.3rem;
}

#merge-grid-container-body {
  width: 100%;

  display: flex;
}

.merge-grid-item {
  width: 300px;

  display: flex;
  flex-direction: column;
}

.merge-grid-item span {
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border: 1px solid #ddd;
}

.merge-grid-item input {
  margin-right: 1rem;
}

.active-tab {
  border-bottom: 5px solid black;
}

.table-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.conflict-row {
  background-color: rgb(236, 164, 164);
}

#merge-row-labels span {
  font-weight: bold;
}

#merge-result span {
  background-color: #e7e4df;
}

.merge-grid-header {
  font-weight: bold;
}

#confirm-message {
  width: 400px;

  padding: 2rem;

  font-size: 14pt;
  box-shadow: 0 5px 10px #ddd;
}

#confirm-message-footer {
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

#confirm-message-body {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#confirm-message-footer button {
  margin-left: 1rem;
}

#support-page-container {
  margin: 1rem;
}
