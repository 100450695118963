#delete-modal-header {
  margin-bottom: 1rem;

  font-size: 16pt;
}

#delete-modal-body {
  margin-left: 1rem;
}

#delete-modal-footer {
  display: flex;

  margin-top: 1rem;
}
