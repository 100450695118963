#user-button {
  cursor: pointer;
}

#user-menu-tooltip {
  padding: 0.3rem 1rem;

  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #f0f0f0;

  &:hover {
    background-color: #ddd;
  }
}

.invisible-tooltip {
  display: none;
}

.content-header-title {
  margin-bottom: 0.5rem;
}

#content-header-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  margin: 1rem;
}

#content-header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin: 1rem;
}

#content-header {
  width: 100%;
  height: 15%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #e1e2e2;
  color: #4b4f54;
}
