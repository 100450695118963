#project-settings-footer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 360px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}
