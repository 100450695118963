#import-tools-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  overflow: scroll;
}

#import-options-container {
  display: flex;
  flex-direction: column;

  font-size: 14pt;
  text-align: center;
}

.import-option {
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  margin: 0 0.7rem;
  font-size: 16pt;
}

.unavailable-import-option {
  filter: grayscale(100%) opacity(25%);
}

.import-option img {
  width: 100px;
}

#import-file-drop {
  width: 40%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#import-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin: 0 0.5rem;
    padding: 0.3rem 0.7rem;

    background-color: #d8d4ce;
    border-radius: 5px;
    color: #4b4f54;
    text-decoration: none;

    &:hover {
      background-color: #bfb8af;
    }
  }
}

#import-options-groups {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#import-confirm-footer-links {
  display: flex;
}

#import-confirm-message {
  text-align: center;
}

.import-data-page-drop-zone {
  width: 450px;
  height: 300px;
}
