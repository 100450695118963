#agreement-selection-tab {
  width: 100%;
  height: 8%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background-color: white;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

#agreement-selection-tab div {
  height: 50px;

  padding: 0 1em;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#agreement-selection-tab p {
  margin: 0;
  padding: 0;
}

#edit-agreements-container {
  width: 100%;
  height: 92%;
}

#edit-agreements-table-container {
  width: 100%;
  height: 100%;
}

.agreement-tab {
  color: black;
  text-decoration: none;
}

.agreement-tab:hover {
  text-decoration: none;
}

.active-tab {
  border-bottom: 5px solid black;
}

// fly out

#edit-contacts-fly-out {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 30%;
  bottom: 9%;
  right: 25px;

  background-color: #d8d4ce;
  transition: width 0.5s;
  box-shadow: -3px 3px 10px #00000033;

  #edit-contacts-fly-out-header {
    margin: 0.5rem;

    font-weight: bold;
  }

  #edit-contacts-fly-out-body {
    margin: 0.5rem;
    padding: 0.2rem;

    font-size: 10pt;
    background-color: white;
  }

  #edit-contacts-fly-out-footer {
    display: flex;
    flex-direction: column;

    margin: 0.5rem;

    font-size: 10pt;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;

    .fly-out-file-item {
      padding: 0.2rem 0.4rem;

      font-size: 10pt;
      color: #555;
      border-bottom: 1px solid #ddd;

      .fly-out-file-date {
        margin-right: 0.5rem;
        color: #999;
      }
    }
  }
}

.hide-fly-out-header {
  display: none;
}

.show-fly-out-header {
  display: flex;
  align-items: center;
}

.hide-fly-out {
  width: 0%;
}

.show-fly-out {
  width: 25%;
}

#retract-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;

  margin: 0.2rem;
  
  border-radius: 10rem;
  transition: background-color 0.2s;
  border: 1px solid #00000033;

  &:hover {
    cursor: pointer;
    background-color: #00000033;
  }
}

#fly-out-label {
  margin-left: 1rem;
}

#fly-out-file-drop-area {
  width: 100%;
  height: 100%;
}

.file-attach-drop-zone {
}
