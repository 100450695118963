#project-settings-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

#project-settings-region {
  background: #d8d4ce;
  border: 4px solid #d8d4ce;
  border-radius: 4px;
  margin: 1rem;

  .project-settings-section:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.settings-header {
  padding-bottom: 0.3rem;
  margin-bottom: 0.1rem;
}

#settings-content {
  background: white;
  padding: 0.6rem 1rem 1rem 1rem;
}

.project-settings-section {
  margin-top: 0.6rem;
  padding-top: 0.6rem;
  border-top: 1px solid #d8d4ce;

  .settings-header {
    padding-bottom: 0.3rem;
    margin-bottom: 0.1rem;
  }
}


.project-settings-entry {
  display: flex;
  justify-content: flex-start;
}

.project-settings-checkbox {
  flex: 2;
}

.project-settings-entry-spacer {
  flex: 3;
}

.project-settings-entry > label {
  padding: 0.3rem;
  margin-bottom: 0.2rem;
  flex: 1;
  min-width: 250px;
}

.project-settings-value {
  padding: 0.3rem;
  flex:3;
}

.project-settings-label-cell {
  margin-left: 0.2rem;
  margin-right: 1rem;
  min-width: 250px;
}

.project-settings-select {
  padding: 1px 2px;
  min-width: 325px;
}

.settings-update-button {
  width: 5rem;
  height: 2.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  margin-left: 1.3rem;

  border: 1px solid #bbb;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }

  span {
    margin-left: 0.3rem;
  }
}

.settings-update-button.inactive {
  background-color: #f0f0f0;
  border-color: #ddd;
  color: #d0d0d0;
}
