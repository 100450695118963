#assign-caa-modal-header {
    margin-bottom: 1rem;
    font-size: 16pt;
}

#assign-caa-modal-body {
    margin-left: 1rem;
}

#assign-caa-modal-footer {
    display: flex;
    margin-top: 1rem;
}