#bypass-eligibility-editor-container {
  height: 400px;
  max-width: 900px;

  border: 2px solid #d8d4ce;
  border-radius: 3px;

  background-color: whitesmoke;

  padding: 0.5rem;

  display: flex;
}

#bypass-container-left, #bypass-container-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

#bypass-container-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bypass-button-group:nth-child(1) {
    margin-bottom: 1rem;
  }
}

.bypass-container-header {
  text-align: center;
  margin-bottom: 0.5rem;
}

.bypass-button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .move-item-button:last-child {
    margin-top: 0;
  }
}

.bypass-eligibility-editor-item-container {
  display: flex;
  flex-direction: column;
  background-color: white;

  height: 100%;
  overflow-y: auto;

  margin-bottom: 0rem;

  border: 1px solid lightgrey;
  border-radius: 2px;
}

.move-item-button {
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;

  min-width: 2rem;
  min-height: 2rem;

  margin: 0.5rem 0.5rem 0.5rem 0.5rem;

  padding: 0.3rem;

  border: 1px solid grey;
  border-radius: 0.3rem;

  background-color: white;

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }

  span {
    margin-left: 0.3rem;
  }
}

.bypass-eligibility-editor-item {
  display: flex;

  user-select: none;

  margin: 0.3rem 0.3rem 0 0.3rem;
  padding: 0.3rem;

  border: 1px solid lightgrey;
  border-radius: 2px;
}


.bypass-eligibility-editor-item.selectable {
  background-color: white;
}

.bypass-eligibility-editor-item.selectable:not(.selected) {
  &:hover {
    background-color: whitesmoke;
  }
}

.bypass-eligibility-editor-item.selected {
  background-color: whitesmoke;
  border-color: darkgrey;
}
