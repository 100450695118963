#page-size-control {
  width: 150px;

  padding: 1rem;
  
  background: white;
  font-size: 13px;
  border: 2px solid #ddd;
  border-radius: 0.3rem;
}

.invisible {
  display: none;
}

#page-size-control-select {
  display: flex;
  flex-direction: column;
}

.page-size-control-option {
  width: 100%;

  padding: 0.5rem;
  margin-bottom: 0.3rem;

  text-align: center;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #ddd;
    cursor: pointer;
  }
}

.selected-page-size {
  color: white;
  font-weight: bold;
  background-color: rgb(0, 132, 255);
}
