#drop-zone {
  // width: 450px;
  // height: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #555555;
}

#drop-zone-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    margin-bottom: 1rem;
  }
}

#drop-zone-file-list {
  margin: 0.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.drop-zone-rejected {
  background-color: #f7dddc;
}

.drop-zone-duplicate {
  background-color: #f7f4dc;
}

.drop-zone-uploaded {
  background-color: #d2ebf5;
}

.drop-zone-dropped {
  background-color: #dbf2e3;
}

.drop-zone-over {
  background-color: #d1d1d1;
}

.drop-zone-inactive {
  background-color: #dadada;
}

.file-gutter-square {
  margin: 0.3em;
}

.file-gutter-square span {
  margin-left: 0.3em;
}

#import-file-select {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5rem;
  transition: background-color 0.5s, color 0.5s;

  label {
    padding-top: 0.3rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.13);
    color: white;
  }
}
