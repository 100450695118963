.file-picker-header {
    margin-bottom: 1rem;
    font-size: 16pt;
  }
  
  
  #file-picker-footer {
    display: flex;
    margin-top: 1rem;
  }
  
  
  .file-attach-drop-zone {
    width: 60%;
    min-height: 10.5rem;
  }
  
  .upload-new-file-region {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    width: 100%;
  }
  
  .uploaded-files-region {
    display: flex;
    flex-direction: column;
  
    width: 100%;
  
    margin-right: 0.5rem;
  }
  
  .uploaded-files-item-container {
    display: flex;
    flex-direction: column;
    background-color: white;
  
    height: 10.5rem;
    overflow-y: auto;
  
    margin-top: 1rem;
    margin-bottom: 0rem;
  
    border: 1px solid lightgrey;
    border-radius: 2px;
  }
  
  .uploaded-file-item {
    display: flex;
  
    user-select: none;
  
    margin: 0 0.1rem 0 0.1rem;
    padding: 0.4rem;
  
    border-top: 1px solid lightgrey;
  
    .upload-date {
      margin-right: 0.5rem;
      color: #999;
    }
  }
  
  .uploaded-file-item:nth-child(1) {
    border: none;
  }
  
  .uploaded-file-item.selectable {
    background-color: white;
  }
  
  .uploaded-file-item.selectable:not(.selected) {
    &:hover {
      background-color: whitesmoke;
    }
  }
  
  .uploaded-file-item.selected {
    background-color: whitesmoke;
    border-color: darkgrey;
      }