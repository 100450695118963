#corner-logo {
  height: 115px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 1rem;
    font-size: 2rem;
  }
}

#left-nav {
  display: flex;
  flex-direction: column;
}

#left-nav a {
  text-decoration: none;
}

#powered-by-ht-logo-container {
  flex-grow: 1;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  margin: 1.5rem 0;
}

.aim-logo-subtext {
  font-size: 10pt;
  color: #999;
}

#powered-by-ht-logo {
  height: 25px;
}
