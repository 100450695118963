.reviewer-feedback-content-item {
    margin-bottom: 1em;
}

.reviewer-feedback-content-item-header {
    display: flex;
    flex-direction: column;

    .reviewer-feedback-content-item-header-title {
        font-weight: bold;
    }

    .reviewer-feedback-content-item-header-time {
        font-size: 10pt;
        color: grey;
    }
}

.reviewer-feedback-content-item-body {}
