.loading-box {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 18pt;
}

.sub-message {
  font-size: 12pt;
}
